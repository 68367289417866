import React from "react"

import Layout from "../global/components/layout"
import { Component } from "react"
import SEO from "../global/components/seo"
import "./eventos.scss"
import List from "../global/components/structure/lists/list/List";

class EventsPage extends Component {

  constructor() {
    super();
    
    this.state = {
      load: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        load: true,
      });
    }, 0);
  }

  render() {
    return <Layout>
        <SEO title="Eventos" />
        <div className="page events" >
          <h1 className="p" >Eventos</h1>
          <List type={"card"} info={{
            type: 'media',
            imageType: 'normal',
            title: 'Próximos, ¡Inscríbete!'
          }} data={[
            {
              title: 'Caches en Drupal',
              description: '<p>Aprendamos juntos como manejar el cache.</p>',
              image: '/eventos/DrupalCamp2020-Colombia.png',
              link: {
                url: '/comunidad-drupal/evento/cache-drupal',
                target: '_blank'
              }
            }
          ]} />
          <List type={"card"} info={{
            type: 'media',
            imageType: 'normal',
            title: 'Anteriores'
          }} data={[
            {
              title: 'DrupalCamp 2020 Colombia - Virtual/Gratuito',
              description: '<p><strong>El 25 y 26 de Septiembre</strong>, no te puedes perder el evento creado para la comunidad Drupal.</p>',
              image: '/eventos/DrupalCamp2020-Colombia.png',
              link: {
                url: 'https://drupalcamp.co/',
                target: '_blank'
              }
            },{
              title: 'DrupalDay 2020 Colombia - Virtual/Gratuito',
              description: '<p>El 6 de Junio, no te puedes perder el evento creado para la comunidad Drupal.</p>',
              image: '/eventos/DrupalDay2020-Colombia.png',
              link: {
                url: 'https://drupalday.co/',
                target: '_blank'
              }
            },{
              title: 'Bases CMS Desacoplado y Testing | Drupal 8 | Meetup',
              description: '<p>CMS desacoplado y Aprendamos lo básico de Testing para Drupal 8</p>',
              image: '/eventos/CMSDesacopladoYTest-BogotaColombia.png',
              link: {
                url: 'https://www.meetup.com/es-ES/Bogota-Drupal-Meetup/events/264400619/',
                target: '_blank'
              }
            },{
              title: 'Se un Heroe Drupal | Meetup',
              description: '<p>Aprende Eventos, Hooks, Plugins y servicios.</p>',
              image: '/eventos/SeUnHeroeDrupal-BogotaColombia.png',
              link: {
                url: '',
                target: '_blank'
              }
            },
          ]} />
        </div>
      </Layout>
  }
}


export default EventsPage
